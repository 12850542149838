import React from "react";
import { useOpenSourceProposals } from "../../hooks/useOpenSourceProposals/useOpenSourceProposals";
import Spacer from "../Spacer/Spacer";
import "./OpenSourceProposalsSection.scss";

const OpenSourceProposalsSection = () => {
  const { title, content } = useOpenSourceProposals();
  return (
    <section className="openSourceProposalsSection">
      <div className="container">
        <Spacer mobileSize={50} size={100} />
        {title && <h1 className="h2"> {title}</h1>}
        <hr size="3" className="divider" />
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            {content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            )}
          </div>
          <div className="col-lg-2" />
        </div>
        <Spacer mobileSize={50} size={150} />
      </div>
    </section>
  );
};

export default OpenSourceProposalsSection;
