import { useStaticQuery, graphql } from "gatsby";
export const useOpenSourceProposals = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Open Source Proposals" } }) {
        content
        title
      }
    }
  `);
  return data.wpPage;
};
