import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import OpenSourceProposalsSection from "../components/OpenSourceProposals/OpenSourceProposalsSection";
import Footer from "../components/Footer/Footer";

const OpenSourceProposals = () => {
  return (
    <Layout>
      <Header />
      <OpenSourceProposalsSection />
      <Footer />
    </Layout>
  );
};

export default OpenSourceProposals;
